import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

// Docaposte agility. ----------------------------------------------------------
import { ProtectedRoute } from "@docaposte-agility/da-react-keycloak";

// Components. -----------------------------------------------------------------
import Loading from "app/pages/Loading";

// Lazy loading. ---------------------------------------------------------------
const Accueil = React.lazy(() => import("app/pages/Accueil"));
const NotFound = React.lazy(() => import("app/pages/NotFound"));

const HookForm = React.lazy(() => import("app/pages/HookForm"));
const ArrayField = React.lazy(() => import("app/pages/HookForm/ArrayField"));
const AutocompleteField = React.lazy(
  () => import("app/pages/HookForm/AutocompleteField"),
);
const TextField = React.lazy(() => import("app/pages/HookForm/TextField"));
const SearchField = React.lazy(() => import("app/pages/HookForm/SearchField"));
const CheckBoxField = React.lazy(
  () => import("app/pages/HookForm/CheckBoxField"),
);
const DateField = React.lazy(() => import("app/pages/HookForm/DateField"));
const DropZone = React.lazy(() => import("app/pages/HookForm/DropZone"));
const EmailField = React.lazy(() => import("app/pages/HookForm/EmailField"));
const NumberField = React.lazy(() => import("app/pages/HookForm/NumberField"));
const PasswordField = React.lazy(
  () => import("app/pages/HookForm/PasswordField"),
);
const PhoneField = React.lazy(() => import("app/pages/HookForm/PhoneField"));
const RadioField = React.lazy(() => import("app/pages/HookForm/RadioField"));
const SelectField = React.lazy(() => import("app/pages/HookForm/SelectField"));
const SocialSecurityNumberField = React.lazy(
  () => import("app/pages/HookForm/SocialSecurityNumberField"),
);
const ZipCodeField = React.lazy(
  () => import("app/pages/HookForm/ZipCodeField"),
);

const ToggleField = React.lazy(() => import("app/pages/HookForm/ToggleField"));
const ArraySelect = React.lazy(() => import("app/pages/ArraySelect"));
const ArraySingleSelect = React.lazy(
  () => import("app/pages/ArraySelect/ArraySingleSelect"),
);
const ArrayMultiSelect = React.lazy(
  () => import("app/pages/ArraySelect/ArrayMultiSelect"),
);

const MultiLang = React.lazy(() => import("app/pages/MultiLang"));
const I18nLang = React.lazy(() => import("app/pages/MultiLang/I18nLang"));
const I18nNamespace = React.lazy(
  () => import("app/pages/MultiLang/I18nNamespace"),
);

const Help = React.lazy(() => import("app/pages/Help"));
const HelpFullDoc = React.lazy(() => import("app/pages/Help/HelpFullDoc"));

// =============================================================================
// Router...
// =============================================================================
const Router = () => {
  // Render. -------------------------------------------------------------------
  return (
    <BrowserRouter>
      <React.Suspense fallback={<Loading />}>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute
                roles={[window.__ENV__.REACT_APP_KEYCLOAK_ROLE]}
                component={Accueil}
                fallbackComponent={Loading}
              />
            }
          />

          {/* HookForm. ---------------------------------------------------- */}
          <Route
            path="/hookForm"
            element={
              <ProtectedRoute
                roles={[window.__ENV__.REACT_APP_KEYCLOAK_ROLE]}
                component={HookForm}
                fallbackComponent={Loading}
              />
            }
          />
          <Route
            path="/hookForm/arrayField"
            element={
              <ProtectedRoute
                roles={[window.__ENV__.REACT_APP_KEYCLOAK_ROLE]}
                component={ArrayField}
                fallbackComponent={Loading}
              />
            }
          />
          <Route
            path="/hookForm/autocompleteField"
            element={
              <ProtectedRoute
                roles={[window.__ENV__.REACT_APP_KEYCLOAK_ROLE]}
                component={AutocompleteField}
                fallbackComponent={Loading}
              />
            }
          />
          <Route
            path="/hookForm/textField"
            element={
              <ProtectedRoute
                roles={[window.__ENV__.REACT_APP_KEYCLOAK_ROLE]}
                component={TextField}
                fallbackComponent={Loading}
              />
            }
          />
          <Route
            path="/hookForm/searchField"
            element={
              <ProtectedRoute
                roles={[window.__ENV__.REACT_APP_KEYCLOAK_ROLE]}
                component={SearchField}
                fallbackComponent={Loading}
              />
            }
          />
          <Route
            path="/hookForm/checkboxField"
            element={
              <ProtectedRoute
                roles={[window.__ENV__.REACT_APP_KEYCLOAK_ROLE]}
                component={CheckBoxField}
                fallbackComponent={Loading}
              />
            }
          />
          <Route
            path="/hookForm/dateField"
            element={
              <ProtectedRoute
                roles={[window.__ENV__.REACT_APP_KEYCLOAK_ROLE]}
                component={DateField}
                fallbackComponent={Loading}
              />
            }
          />
          <Route
            path="/hookForm/dropZone"
            element={
              <ProtectedRoute
                roles={[window.__ENV__.REACT_APP_KEYCLOAK_ROLE]}
                component={DropZone}
                fallbackComponent={Loading}
              />
            }
          />
          <Route
            path="/hookForm/emailField"
            element={
              <ProtectedRoute
                roles={[window.__ENV__.REACT_APP_KEYCLOAK_ROLE]}
                component={EmailField}
                fallbackComponent={Loading}
              />
            }
          />
          <Route
            path="/hookForm/numberField"
            element={
              <ProtectedRoute
                roles={[window.__ENV__.REACT_APP_KEYCLOAK_ROLE]}
                component={NumberField}
                fallbackComponent={Loading}
              />
            }
          />
          <Route
            path="/hookForm/phoneField"
            element={
              <ProtectedRoute
                roles={[window.__ENV__.REACT_APP_KEYCLOAK_ROLE]}
                component={PhoneField}
                fallbackComponent={Loading}
              />
            }
          />
          <Route
            path="/hookForm/radioField"
            element={
              <ProtectedRoute
                roles={[window.__ENV__.REACT_APP_KEYCLOAK_ROLE]}
                component={RadioField}
                fallbackComponent={Loading}
              />
            }
          />
          <Route
            path="/hookForm/selectField"
            element={
              <ProtectedRoute
                roles={[window.__ENV__.REACT_APP_KEYCLOAK_ROLE]}
                component={SelectField}
                fallbackComponent={Loading}
              />
            }
          />
          <Route
            path="/hookForm/socialSecurityNumberField"
            element={
              <ProtectedRoute
                roles={[window.__ENV__.REACT_APP_KEYCLOAK_ROLE]}
                component={SocialSecurityNumberField}
                fallbackComponent={Loading}
              />
            }
          />
          <Route
            path="/hookForm/zipCodeField"
            element={
              <ProtectedRoute
                roles={[window.__ENV__.REACT_APP_KEYCLOAK_ROLE]}
                component={ZipCodeField}
                fallbackComponent={Loading}
              />
            }
          />
          <Route
            path="/hookForm/toggleField"
            element={
              <ProtectedRoute
                roles={[window.__ENV__.REACT_APP_KEYCLOAK_ROLE]}
                component={ToggleField}
                fallbackComponent={Loading}
              />
            }
          />
          <Route
            path="/hookForm/passwordField"
            element={
              <ProtectedRoute
                roles={[window.__ENV__.REACT_APP_KEYCLOAK_ROLE]}
                component={PasswordField}
                fallbackComponent={Loading}
              />
            }
          />

          {/* Array. --------------------------------------------------------- */}
          <Route
            path="/array"
            element={
              <ProtectedRoute
                roles={[window.__ENV__.REACT_APP_KEYCLOAK_ROLE]}
                component={ArraySelect}
                fallbackComponent={Loading}
              />
            }
          />
          <Route
            path="/array/arraySingleSelect"
            element={
              <ProtectedRoute
                roles={[window.__ENV__.REACT_APP_KEYCLOAK_ROLE]}
                component={ArraySingleSelect}
                fallbackComponent={Loading}
              />
            }
          />
          <Route
            path="/array/arrayMultiSelect"
            element={
              <ProtectedRoute
                roles={[window.__ENV__.REACT_APP_KEYCLOAK_ROLE]}
                component={ArrayMultiSelect}
                fallbackComponent={Loading}
              />
            }
          />

          {/* Multi Lang. -------------------------------------------------- */}
          <Route
            path="/multiLang"
            element={
              <ProtectedRoute
                roles={[window.__ENV__.REACT_APP_KEYCLOAK_ROLE]}
                component={MultiLang}
                fallbackComponent={Loading}
              />
            }
          />
          <Route
            path="/multiLang/lang"
            element={
              <ProtectedRoute
                roles={[window.__ENV__.REACT_APP_KEYCLOAK_ROLE]}
                component={I18nLang}
                fallbackComponent={Loading}
              />
            }
          />
          <Route
            path="/multiLang/namespace"
            element={
              <ProtectedRoute
                roles={[window.__ENV__.REACT_APP_KEYCLOAK_ROLE]}
                component={I18nNamespace}
                fallbackComponent={Loading}
              />
            }
          />

          {/* Help. -------------------------------------------------------- */}
          <Route
            path="/help"
            element={
              <ProtectedRoute
                roles={[window.__ENV__.REACT_APP_KEYCLOAK_ROLE]}
                component={Help}
                fallbackComponent={Loading}
              />
            }
          />
          <Route
            path="/help/fullDoc"
            element={
              <ProtectedRoute
                roles={[window.__ENV__.REACT_APP_KEYCLOAK_ROLE]}
                component={HelpFullDoc}
                fallbackComponent={Loading}
              />
            }
          />

          {/* Not found. ----------------------------------------------------- */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default Router;
