// Components. -----------------------------------------------------------------
import LoginProvider from "app/providers/LoginProvider";
import DAProvider from "app/providers/DAProvider";
import Router from "app/Router";

// Utils. ----------------------------------------------------------------------
import { dateLog } from "utils/date/dateLog";

// Types/const. ----------------------------------------------------------------
import packageJson from "../../package.json";
import ProjectProvider from "app/providers/ProjectProvider";

// =============================================================================
// App...
// =============================================================================
function App() {
  // Display versions. ---------------------------------------------------
  if (process.env.NODE_ENV === "development") {
    console.log(
      dateLog("DA", packageJson.name + " - V" + packageJson.version + ".dev"),
    );
  } else {
    console.debug = (...args) => {};
    console.log(dateLog("DA", packageJson.name + " - V" + packageJson.version));
  }

  // render. -------------------------------------------------------------------
  return (
    <DAProvider>
      <LoginProvider>
        <ProjectProvider>
          <Router />
        </ProjectProvider>
      </LoginProvider>
    </DAProvider>
  );
}
export default App;
