import React from "react";
import { Grid, CircularProgress } from "@mui/material";

// =============================================================================
// Loading...
// =============================================================================
interface LoadingProps {}
const Loading: React.FC<LoadingProps> = () => {
  // Render. -------------------------------------------------------------------
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "100vh",
        minWidth: "100vw",
        background: `url("/images/background.png") center center no-repeat fixed`,
        backgroundSize: "cover",
        margin: 0,
        padding: 0,
        outline: 0,
      }}
    >
      <Grid
        item
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        xs
      >
        <CircularProgress
          size="30vh"
          thickness={1.5}
          sx={{ color: "cobalt.lynch" }}
        />
      </Grid>

      {/* Logo. ------------------------------------------------------------ */}
      <Grid
        item
        container
        direction="column"
        justifyContent="flex-top"
        alignItems="flex-end"
        sx={{ height: "40px", p: "0px 10px" }}
      >
        <img
          src="/images/Tech-Trust.svg"
          style={{
            border: "none",
            height: "30px",
            pointerEvents: "none",
          }}
          alt="logo-docaposte"
        />
      </Grid>
    </Grid>
  );
};
export default Loading;
