import Axios, { AxiosResponse } from "axios";

// =============================================================================
// executeRequestInterceptor...
// =============================================================================
export const executeRequestInterceptor = () => {
  Axios.interceptors.request.use(
    (config) => {
      config.baseURL = ".";
      return config;
    },
    (error) => Promise.reject(error),
  );
};

// =============================================================================
// executeResponseInterceptor...
// =============================================================================
export const executeResponseInterceptor = () => {
  Axios.interceptors.response.use((response: AxiosResponse) => {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(response);
      }, 1000),
    );
  });
};
