import * as React from "react";
import Toaster, {
  ToasterType,
  emptyToaster,
} from "app/components/common/Toaster";

// Docaposte agility. ----------------------------------------------------------

// =============================================================================
// ToasterContext...
// =============================================================================
interface ToasterContextValue {
  toasterMessage: ToasterType;
  setToasterMessage: (toaster: ToasterType) => void;
}
export const ToasterContext = React.createContext<ToasterContextValue>({
  toasterMessage: emptyToaster,
  setToasterMessage: () => undefined,
});

// =============================================================================
// ToasterProvider...
// =============================================================================
interface ToasterProviderProps {
  children: React.ReactNode;
}
const ToasterProvider: React.FC<ToasterProviderProps> = ({ children }) => {
  const [toasterMessage, setToasterMessage] =
    React.useState<ToasterType>(emptyToaster);

  // Hooks. --------------------------------------------------------------------
  const value = React.useMemo(
    () => ({
      toasterMessage,
      setToasterMessage,
    }),
    [toasterMessage],
  );

  // Provider. -----------------------------------------------------------------
  return (
    <ToasterContext.Provider value={value}>
      {toasterMessage.type !== "default" && (
        <Toaster message={toasterMessage} />
      )}
      {children}
    </ToasterContext.Provider>
  );
};
export default ToasterProvider;
