import React from "react";

// Docaposte agility. ----------------------------------------------------------
import { KeycloakProvider } from "@docaposte-agility/da-react-keycloak";

// Utils. ----------------------------------------------------------------------
import { dateLog } from "utils/date/dateLog";

// =============================================================================
// LoginProvider...
// =============================================================================
const LoginProvider = ({ children }) => {
  // Render. -------------------------------------------------------------------
  return (
    <KeycloakProvider
      authClient={window.__ENV__.KEYCLOAK_CONFIG}
      onEvent={(event) => {
        console.debug(dateLog("KCK", "onKeycloakEvent"), event);
      }}
      onTokens={(tokens) => {
        console.debug(dateLog("KCK", "onKeycloakTokens"), tokens);
      }}
      onError={(event) => {
        console.debug(dateLog("KCK", "onKeycloakError"), event);
      }}
    >
      {children}
    </KeycloakProvider>
  );
};
export default LoginProvider;
