import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { Stack, Typography, useMediaQuery } from "@mui/material";

// Docaposte agility. ----------------------------------------------------------
import { CobaltIcon } from "@docaposte-agility/da-design-system";

// Style. ----------------------------------------------------------------------
import "react-toastify/dist/ReactToastify.css";

// Exported Interface/Types/Const. ---------------------------------------------
export interface ToasterType {
  type: TypeToaster;
  title: string;
  message: string;
  autoClose?: number;
  toastId?: string;
}
export const emptyToaster = {
  type: "default" as TypeToaster,
  title: "",
  message: "",
  autoClose: undefined,
  toastId: undefined,
};
export type TypeToaster = "info" | "success" | "warning" | "error" | "default";

// =============================================================================
// Toaster.
// =============================================================================
interface ToasterProps {
  message: ToasterType;
}
const Toaster: React.FC<ToasterProps> = ({ message }) => {
  // Members. ------------------------------------------------------------------
  const isMobile = useMediaQuery("(max-width:500px)");

  // Handlers. -----------------------------------------------------------------
  const handleClose = () => {
    toast.dismiss(message.toastId);
  };

  // Functions. ----------------------------------------------------------------
  const CloseButton = (closeToast) => (
    <CobaltIcon
      onClick={closeToast}
      name="x"
      style={{
        position: "absolute",
        top: "10px",
        right: "10px",
        cursor: "pointer",
      }}
    />
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderToastContent = () => (
    <Stack
      style={{
        background: "cobalt.white",
        paddingLeft: "10px",
        paddingRight: "auto",
      }}
      onClick={handleClose}
    >
      <Stack
        style={{
          paddingTop: "4px",
          paddingBottom: "4px",
          marginBottom: "10px",
        }}
      >
        <Typography variant="h4">{message.title}</Typography>
      </Stack>
      <Typography
        variant="body1"
        sx={{ whiteSpace: "pre-line", pl: "0px", color: "cobalt.lynch" }}
      >
        {message.message}
      </Typography>
      <CloseButton closeToast={handleClose} />
    </Stack>
  );

  // Hooks. --------------------------------------------------------------------
  React.useEffect(() => {
    if (message.type !== "default") {
      toast(renderToastContent, {
        position: isMobile
          ? toast.POSITION.TOP_CENTER
          : toast.POSITION.TOP_RIGHT,
        type: message.type,
        toastId: message.toastId ?? message.toastId,
        autoClose: message.autoClose,
        hideProgressBar: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  // Render. -------------------------------------------------------------------
  return (
    <ToastContainer
      style={isMobile ? { width: "100%" } : { width: "500px" }}
      closeButton={false}
      autoClose={message.autoClose}
      // limit={5}
      closeOnClick={false}
      pauseOnHover={false}
    />
  );
};
export default Toaster;
