import { I18nextProvider } from "react-i18next";

// Configs. --------------------------------------------------------------------
import i18n from "config/i18n";

// Contexts. -------------------------------------------------------------------
import ToasterProvider from "app/context/ToasterContext";

// =============================================================================
// ProjectProvider...
// =============================================================================
const ProjectProvider = ({ children }) => {
  // Render. -------------------------------------------------------------------
  return (
    <I18nextProvider i18n={i18n}>
      <ToasterProvider>{children}</ToasterProvider>
    </I18nextProvider>
  );
};
export default ProjectProvider;
