import { createTheme } from "@mui/material/styles";
import { theme, colors } from "@docaposte-agility/da-design-system";

export default createTheme(theme, {
  palette: {
    background: {
      default: colors.light,
    },
  },
});
